@import 'variables';

form {
  .form-text {
    display: block;
    line-height: 1.5;
  }
  .form-check {
    .form-check-label {
      display: block;
      margin-bottom: 0;
    }
    .form-text {
      margin-left: -1 * $form-check-padding-start;
    }
  }
  .form-group {
    .flex-row {
      > select {
        flex-grow: 0;
        width: auto;
      }
    }
  }
}