@use 'sass:math';
@import 'variables';

html {
  min-height: 100%;
  padding: env(safe-area-inset);
}

body {
  min-height: 100vh;
  min-height: 100svh;
  display: flex;
  flex-direction: column;

  --fa-primary-color: var(--bs-primary);
  --fa-secondary-color: #{$indigo-200};
  --fa-secondary-opacity: 1;

  #main-breadcrumb .breadcrumb{
    font-weight: 200;
  }

  main {
    padding-bottom: $spacer;
  }

  .participating-organisations-container {
    .participating-organisations-list {
      margin: 0 math.div($spacer, 2);

      li {
        display: inline;
        margin: 0;
      }

      li+li {
        &:before {
          content: ', ';
        }
      }
    }
  }

  footer {
    margin-top: auto;
    border-top: 1px solid var(--bs-border-color, #{$border-color});
  }
}