@use "sass:math";
@import "variables";
@import '~bootstrap/scss/mixins';

.btn.btn-default {

  @include button-variant($white,
    $gray-400,
    $hover-background: $gray-200,
    $hover-border: $gray-400,
    $active-background: $gray-300 );
  white-space: nowrap;
}

td .btn-group-vertical {
  flex-direction: row;
  flex-wrap: wrap;

  > .btn {
    width: auto;
  }
  > .btn:not(:first-child),  > .btn-group:not(:first-child) {
    margin-top: 0;
  }
  gap: math.div($spacer, 3);

  @include media-breakpoint-up(lg) {
    flex-direction: column;
    > .btn {
      width: 100%;
    }
  }
}