@use 'sass:math';
@import 'variables';
@import '~bootstrap/scss/mixins';

#main-nav {
  font-family: $headings-font-family;

  #current-user-icon {
      --fa-primary-color: #{$indigo-200};
      --fa-secondary-color: var(--bs-primary);
      --fa-secondary-opacity: 1;
    }

  .navbar-nav {
    .divider {
      width: 1px;
      margin-top: var(--bs-nav-link-padding-y);
      margin-bottom: var(--bs-nav-link-padding-y);
      border-left: 1px solid var(--bs-border-color);
    }

    .nav-link.active {
      position: relative;
      font-weight: 600;
      @include media-breakpoint-up(lg) {
        &:before {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          height: 5px;
          background-color: var(--bs-cyan);
          margin-top: math.div($spacer, -2);
        }
      }
    }
  }
}

.nav-tabs {
  font-family: $headings-font-family;
  border-bottom: 3px solid $indigo-200;

  .nav-link {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: -3px;
      height: 3px;

    }

    &:hover {
      &:before {
        background-color: var(--bs-cyan);
      }
    }

    &.active {
      &:before {
        background-color: var(--bs-primary);
      }
    }
  }
}

.tab-content {
  border: none;
  --bs-card-cap-padding-y: #{$spacer};
  --bs-card-border-color: var(--bs-light);
  --bs-card-border-width: 3px;
  --bs-card-cap-bg: var(--bs-white);
}