@import 'variables';

.notification {

  display: flex;

  & + .notification {
    border-top: 1px solid var(--bs-border-color);
  }

  .notification-title {
    font-weight: 600;
  }

  .notification-message {
    padding: $spacer;

    span,
    .reasonable-adjustment-info {
      font-weight: 300;
    }

    .reasonable-adjustment-info {
      margin-bottom: 10px;
    }
  }

  .notification-action-link {
    margin-left: auto;
    padding: $spacer;
    display: flex;

    align-items: center;
    justify-content: center;

    min-width: 6rem;

    border: none;

    &:hover,
    &:active,
    &:focus,
    &:visited {
      text-decoration: none;
    }

  }
}