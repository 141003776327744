@use 'sass:math';
@import '../variables';
@import '~bootstrap/scss/mixins';


html {
  -webkit-print-color-adjust: exact;
  print-color-adjust: exact;
}

.exam-results {
  display: flex;
  flex-direction: column;
  gap: $spacer * 2;
}
.category-results {
  display: grid;
  grid-gap: $spacer;
  grid-auto-flow: row;

  @include media-breakpoint-up(lg) {
    grid-template-columns: 1fr 1fr;
  }
}

.category-result {
  @media print {
    break-inside: avoid;
  }

  border: 1px solid var(--bs-border-color-translucent);

  min-height: $font-size-base * $line-height-base * 2 + $spacer * 3;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .category-result-title {
    padding: $spacer;
    // flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    gap: $spacer;
  }

  .category-result-percent {
    flex: 0 0 9ch;
    font-size: $h3-font-size;
    line-height: 1;
    font-weight: 300;
    padding: $spacer;
    display: flex;

    align-self: stretch;
    justify-content: center;
    align-items: center;
  }

  &.overall-result {
    border-color: var(--bs-primary);

    .category-result-percent {
      background-color: var(--bs-primary);
      color: #fff;
    }

    &.failed {
      border-color: var(--bs-danger);

      .category-result-percent {
        background-color: var(--bs-danger);
        color: #fff;
      }
    }
  }
}
